import { gsap } from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

import {selectAllEl, selectEl} from "./utils";

const viewportWidth = window.visualViewport.width
const isMobileDevice = viewportWidth < 991

export const  navBarFixed = () =>  {
    const nav = isMobileDevice ? selectEl('.site-header_mobile .bottom-header') : selectEl('.site-header_desktop .bottom-header')
    if(!nav) return
    gsap.to(nav, {
        scrollTrigger: {
            trigger: isMobileDevice ? '.site-header_mobile .top-header' : '.site-header_desktop .top-header',
            start: 'bottom top',
            end: '+=9999999',
            // markers: true,
            pin: nav,
            pinSpacing: false,
            onEnter: () => {
                nav.classList.add('fixed')
            },
            onLeaveBack: () => {
                nav.classList.remove('fixed')
            }
        }
    })
}

export const newsTicker = () =>  {
    const speed = 50;
    let target = selectEl(".newsTicker")
    if(!target) {
        return
    }
    let original_html = target.innerHTML;
    let new_html = "<div class='ticker-items is-flex col-gap-64'>" + original_html + "</div>";
    target.innerHTML = new_html;
    target.innerHTML += new_html;
    target.innerHTML += new_html;

    let tickerWidth = selectEl(".ticker-items").offsetWidth;
    let initDuration = tickerWidth / speed;

    const tween = gsap.to(".ticker-items", {
        duration: initDuration,
        xPercent: -200,
        ease: "none",
        repeat: -1,
    });

    const infoBanner = selectEl("header .info-banner")
    infoBanner.addEventListener('mouseenter', () => tween.pause())
    infoBanner.addEventListener('mouseleave', () => tween.play())
}

export const jsLazyLoadImage = () =>  {
    ScrollTrigger.config({ limitCallbacks: true });

    gsap.utils.toArray(".js-lazy").forEach(image => {
        let newSRC = image.getAttribute('data-src')
        if( viewportWidth < 768) {
            newSRC = image.getAttribute('data-src-mobile') ?? image.getAttribute('data-src')
        } else if(viewportWidth < 1024) {
            newSRC = image.getAttribute('data-src-tablet') ?? image.getAttribute('data-src')
        }
        let newImage = document.createElement("img"),
            loaded,
            st,
            loadImage = () => {
                if (!loaded) {
                    if(image.tagName === 'VIDEO') {
                        image.poster = image.getAttribute('data-poster')
                    } else {
                        newImage.onload = () => {
                            newImage.onload = null; // avoid recursion
                            newImage.src = image.src; // swap the src
                            image.src = newSRC;
                            // place the low-res version on TOP and then fade it out.
                            gsap.set(newImage, {
                                position: "absolute",
                                top: image.offsetTop,
                                left: image.offsetLeft,
                                width: image.offsetWidth,
                                height: image.offsetHeight
                            });
                            image.parentNode.appendChild(newImage);
                            gsap.to(newImage, {
                                opacity: 0,
                                onComplete: () => newImage.parentNode.removeChild(newImage)
                            });
                            st && st.kill();
                        }
                        newImage.src = newSRC;
                    }
                    loaded = true;
                }
            };

        st = ScrollTrigger.create({
            trigger: image,
            start: "-50% bottom",
            onEnter: loadImage,
            onEnterBack: loadImage // make sure it works in either direction
        })
    });

}

export function initAccordion() {
    const accItem = selectAllEl(".acc-item");
    accItem.forEach((item, index) => {
        let accHeader = item.querySelector(".acc-item_header");
        accHeader.addEventListener("click", () => {
            let accBody = item.querySelector(".acc-item_body");
            if (!item.classList.contains("acc-item_active")) {
                item.classList.add("acc-item_active");
                accBody.style.maxHeight = accBody.scrollHeight + 32 + "px";
                accItem.forEach((element, ind) => {
                    let elementBody = element.querySelector(".acc-item_body");
                    if (!(ind === index)) {
                        element.classList.remove("acc-item_active");
                        elementBody.style.maxHeight = null;
                    }
                });
            } else {
                item.classList.remove("acc-item_active");
                accBody.style.maxHeight = null;
            }
        });
    });
}

export function initModal() {
    const modalTriggers = selectAllEl('[data-modal]')
    modalTriggers.forEach(trigger => {
        trigger.addEventListener('click', function() {
            const target = trigger.getAttribute('data-modal')
            document.getElementById(target).classList.add('modal_show')
        })
    })

    const modalCloseIcons = selectAllEl('.modal_close')
    modalCloseIcons.forEach(closeIcon => {
        closeIcon.addEventListener('click', function() {
            closeIcon.closest('.modal').classList.remove('modal_show')
        })
    })

    selectEl('.modal').addEventListener('click', function(e) {
        const target = e.target
        if(target.classList.contains('modal_show')) {
            target.classList.remove('modal_show')
        }
    })
}