import Player from '@vimeo/player'
import {selectAllEl} from "./utils";

export const  initVideoControls = () => {
    const videos = selectAllEl('.video-wrapper video')
    videos.forEach(video => {
        const parent = video.closest('.video-wrapper')
        const playPause = parent.querySelector('.play-pause')
        const muteUnmute = parent.querySelector('.mute-unmute')
        const seek = parent.querySelector('.seek')
        const seekTooltip = parent.querySelector('.seek-tooltip');
        const duration = parent.querySelector('.duration')
        const progressBar = parent.querySelector('.progress-bar');
        const timeElapsed = parent.querySelector('.time-elapsed')
        const progressBarWrapper = parent.querySelector('.progress-bar-wrapper')

        video.onended = function() {
            playPause.classList.remove('is-playing')
        };

        video.onpause = function() {
            playPause.classList.remove('is-playing')
        };

        video.onplaying = function() {
            initializePlayer(video.duration, duration, seek, progressBar) // If failed on metadata load
            playPause.classList.add('is-playing')
            if(video.muted) {
                muteUnmute.classList.add('muted')
            } else {
                muteUnmute.classList.remove('muted')
            }
        };

        video.addEventListener('loadedmetadata', () => initializePlayer(video.duration, duration, seek, progressBar));

        video.addEventListener('timeupdate', () => {
            updateTimeElapsed( video.currentTime, timeElapsed)
            updateProgress(video.currentTime, seek, progressBar)
        });

        playPause?.addEventListener('click', (e) => {
            e.preventDefault()
            if(video.paused) {
                video.play()
            } else {
                video.pause()
            }
        })

        muteUnmute?.addEventListener('click', (e) => {
            e.preventDefault()
            video.muted = !video.muted
            if(video.muted) {
                muteUnmute.classList.add('muted')
            } else {
                muteUnmute.classList.remove('muted')
            }
        })

        parent.querySelector('.toggle-fullscreen')?.addEventListener('click', () => {
            toggleFullscreen(parent)
        })

        if(progressBarWrapper) {
            seek.addEventListener('mousemove', (e) => updateSeekTooltip(e, seek, seekTooltip, progressBarWrapper.getBoundingClientRect()));

            seek.addEventListener('input', (e) => {
                const skipTo = e.target.dataset.seek ? e.target.dataset.seek : e.target.value;
                video.currentTime = skipTo
                progressBar.value = skipTo;
                seek.value = skipTo;
            } );
        }
    })

    const vimeoVideos = selectAllEl('.js-vimeo-player')
    vimeoVideos.forEach(video => {
        initVimeoVideo(video)
    })
}

function initVimeoVideo(video) {
    const parent = video.closest('.video-wrapper')
    const playPause = parent.querySelector('.play-pause')
    const muteUnmute = parent.querySelector('.mute-unmute')
    const seek = parent.querySelector('.seek')
    const seekTooltip = parent.querySelector('.seek-tooltip');
    const progressBar = parent.querySelector('.progress-bar');
    const timeElapsed = parent.querySelector('.time-elapsed')
    const progressBarWrapper = parent.querySelector('.progress-bar-wrapper')

    const options = {
        id: video.getAttribute('data-video-id'),
        width: parent.offsetWidth + 'px',
        height: parent.offsetHeight + 'px',
        controls: false,
        title: false,
        autopip: false,
        pip: false,
        muted: video.getAttribute('data-muted') ? true : false ,
        loop: video.getAttribute('data-loop') ? true : false,
        responsive: true,
        end_screen: {
            type: 'loop',
        }
    };

    const player = new Player(video, options);

    player.getDuration().then(function(duration) {
        seek.setAttribute('max', duration);
        progressBar.setAttribute('max', duration);

        const time = formatTime(duration);
        const totalTime = parent.querySelector('.duration');
        totalTime.innerText = `${time.minutes}:${time.seconds}`;
        totalTime.setAttribute('datetime', `${time.minutes}m ${time.seconds}s`)
    });
    player.on('playing', () => {
        playPause.classList.add('is-playing')
        player.getMuted().then(( muted) => {
            if(muted) {
                muteUnmute.classList.add('muted')
            } else {
                muteUnmute.classList.remove('muted')
            }
        })
    })
    player.on('timeupdate', (update) => {
        updateTimeElapsed(update.seconds, timeElapsed)
        updateProgress(update.seconds, seek, progressBar)
    })
    player.on('pause', () => {
        playPause.classList.remove('is-playing')
    })
    player.on('ended', () => {
        playPause.classList.remove('is-playing')
        player.setCurrentTime(0)
    })

    parent.querySelector('.play').addEventListener('click', () => {
        player.play()
        playPause.classList.add('is-playing')
    })
    parent.querySelector('.pause').addEventListener('click', () => {
        player.pause()
        playPause.classList.remove('is-playing')
    })
    parent.querySelector('.mute').addEventListener('click', () => {
        player.setVolume(1)
        muteUnmute.classList.remove('muted')
    })
    parent.querySelector('.unmute').addEventListener('click', () => {
        player.setVolume(0)
        muteUnmute.classList.add('muted')
    })

    parent.querySelector('.toggle-fullscreen')?.addEventListener('click', () => {
        toggleFullscreen(parent)
    })

    if(progressBarWrapper)
    {
        seek.addEventListener('mousemove', (e) => updateSeekTooltip(e, seek, seekTooltip, progressBarWrapper.getBoundingClientRect()));
        seek.addEventListener('input', (e) => {
            const skipTo = e.target.dataset.seek ? e.target.dataset.seek : e.target.value;
            player.setCurrentTime(skipTo)
        } );
    }
}

function initializePlayer(totalTime, duration, seek, progressBar) {
    if(!progressBar) {
        return
    }
    const videoDuration = Math.round(totalTime);
    const time = formatTime(videoDuration);
    duration.innerText = `${time.minutes}:${time.seconds}`;
    duration.setAttribute('datetime', `${time.minutes}m ${time.seconds}s`)
    seek.setAttribute('max', videoDuration);
    progressBar.setAttribute('max', videoDuration);
}

function formatTime(timeInSeconds) {
    const result = new Date(timeInSeconds * 1000).toISOString().substring(14, 19);
    return {
        minutes: result.substring(0, 2),
        seconds: result.substring(3, 5),
    }
}

function updateTimeElapsed(currentTime, timeElapsed) {
    if(!currentTime || !timeElapsed) {
        return
    }
    const time = formatTime(currentTime);
    timeElapsed.innerText = `${time.minutes}:${time.seconds}`;
    timeElapsed.setAttribute('datetime', `${time.minutes}m ${time.seconds}s`)
}

function updateProgress(currentTime, seek, progressBar) {
    if(!progressBar) {
        return
    }
    seek.value = Math.floor(currentTime);
    progressBar.value = Math.floor(currentTime);
}

function updateSeekTooltip(event, seek, seekTooltip, rect) {
    const skipTo = Math.round((event.offsetX / event.target.clientWidth) * parseInt(event.target.getAttribute('max'), 10));
    seek.setAttribute('data-seek', skipTo)
    const t = formatTime(skipTo);
    seekTooltip.innerText = `${t.minutes}:${t.seconds}`;
    seekTooltip.style.left = `${event.pageX - rect.left}px`;
}

function toggleFullscreen(videoWrapper) {
    // Check for fullscreen support
    if (document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement || document.msFullscreenElement) {

        // If there's currently an element fullscreen, exit fullscreen
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        }
        // Do stuff when the video exits fullscreen mode
        videoWrapper.classList.remove('full-screen-mode')

    } else {

        // Otherwise, enter fullscreen
        // `videoWrapper` is just a `div` element wrapping the video
        if (videoWrapper.requestFullscreen) {
            videoWrapper.requestFullscreen();
        } else if (videoWrapper.mozRequestFullScreen) {
            videoWrapper.mozRequestFullScreen();
        } else if (videoWrapper.webkitRequestFullscreen) {
            videoWrapper.webkitRequestFullscreen();
        } else if (videoWrapper.msRequestFullscreen) {
            videoWrapper.msRequestFullscreen();
        }

        // Do stuff when the video enters fullscreen mode
        videoWrapper.classList.add('full-screen-mode')
    }
};