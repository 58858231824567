import {gsap} from "gsap";
import Swiper from "swiper";
import { Pagination } from 'swiper/modules';
import {ScrollTrigger} from "gsap/ScrollTrigger";

export const selectEl = (selector) => document.querySelector(selector)
export const selectAllEl = (selector) => document.querySelectorAll(selector)

export const scrollToHash = (hash) => {
    setTimeout(function () {
        gsap.to(window, {
            duration: 1,
            scrollTo: hash,
            ease: "Power1.easeInOut"
        });
    }, 1000)
}

export const testimonialAnimation = () =>  {
    ScrollTrigger.matchMedia({
        "(min-width: 1442px)": () => {
            const tRows = gsap.utils.toArray('.testimonial-row')
            tRows.forEach((row, i) => {
                gsap.to(row,
                    {
                        ease: 'none',
                        x: i % 2 === 0 ? "-60vw" : '60vw',
                        scrollTrigger: {
                            trigger: '.testimonials-section',
                            start: 'top 50%',
                            end: 'bottom 60%',
                            scrub: true,
                            // markers: true
                        }
                    })
            })
        },
        "(min-width: 992px) and (max-width: 1441px)": () => {
            const tRows = gsap.utils.toArray('.testimonial-row')
            const firstRowTranslateX = window.innerWidth > 1024 ? -100 : -150
            const secondRowTranslateX = window.innerWidth > 1024 ? 50 : 150
            tRows.forEach((row, i) => {
                gsap.to(row,
                    {
                        ease: 'none',
                        xPercent: i % 2 === 0 ? firstRowTranslateX : secondRowTranslateX,
                        scrollTrigger: {
                            trigger: row,
                            start: 'top 20%',
                            end: 'bottom 20%',
                            scrub: true,
                            // markers: true,
                            pin: true
                        }
                    })
            })
        },
        "(max-width: 991px)": () => {
            testimonialSlider()
        },
    })
}

export const testimonialSlider = () => {
    const pagination = document.createElement('div')

    pagination.classList.add('swiper-pagination')
    // selectEl('.testimonials').classList.add('swiper', 'testimonialSwiper')
    // selectEl('.testimonials').appendChild(pagination)
    selectEl('.testimonial-row').classList.add('swiper-wrapper')
    gsap.utils.toArray('.testimonial-row.swiper-wrapper .card').forEach(card => {
        card.classList.add('swiper-slide')
    })
    const swiper = new Swiper(".testimonialSwiper", {
        slidesPerView: 1,
        spaceBetween: 22,
        modules: [Pagination],
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        breakpoints: {
            600: {
                slidesPerView: 2,
                spaceBetween: 36
            }
        },
    });
}